import { values } from "pdf-lib";
import React, { useEffect, useState, useMemo, useRef, MutableRefObject } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Grid,
  GridRow,
  Icon,
  Input,
  Item,
  Label,
  Modal,
  TextArea,
} from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";
import CardPostOperationUX from "./CardPostOperationUX";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import CardOperativeNoteTemplate, { SaveHandler, UpdateHandler } from "./CardOperativeNoteTemplate";
import ModalEditImage from "react-lib/apps/common/ModalEditImage";
import WebcamComponent from "../common/WebcamProfile";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

import CONFIG from "config/config";

import "./CardORHistory.scss";
import { useIntl } from "react-intl";

const styles = {
  divTable: {
    width: "100%",
    margin: "5px 10px 0px 0px",
    display: "flex",
    alignItems: "center",
  },
  divCol: {
    width: "26%",
    margin: "5px 10px 0px 0px",
    display: "flex",
    alignItems: "center",
  },
};

const IMAGE = {
  imageBgUpload: "images/picture.gif",
};

const FormUploadImg = {
  operative_note: "",
  image: "",
  description: "",
  pk: "",
};

const diagnosis = {
  icd10_med_term: "medterm",
  icd_term: "icdterm",
  icd_code: "icdcode",
};

const CARD_POST_OPERATION = "CardPostOperation";

const CardPostOperation = (props: any) => {
  const intl = useIntl();
  const [operation, setOperation] = useState<any>([]);
  const [optProcedure, setOptProcedure] = useState<any>([]);
  const [secondary, setSecondary] = useState<any>([]);
  const [openSearchPostResult, setOpenSearchPostResult] = useState<Number>();
  const [openSearchOperation, setOpenSearchOperation] = useState<Number>();
  const [openSearchSecondary, setOpenSearchSecondary] = useState<Number>();
  //Preoperative diagnosis
  const [openSearchPrePrincipal, setOpenSearchPrePrincipal] = useState(false);
  const [openSearchPostPrincipal, setOpenSearchPostPrincipal] = useState(false);
  //
  const [filterTimeOut, setFilterTimeOut] = useState(null);
  //Template
  const [openTemplate, setOpenTemplate] = useState(false);
  const [dataTem, setDataTem] = useState<any>([]);
  //Upload
  const fileUploaderRef = useRef();
  const [selectImage, setSelectImage] = useState();
  const [editedImage, setEditedImage] = useState(FormUploadImg);
  const [uploadImage, setUploadImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(FormUploadImg);
  const [openUploadImg, setOpenUploadImg] = useState(false);
  const [openImageDetail, setOpenImageDetail] = useState(false);
  const [openModalEditImage, setOpenModalEditImage] = useState(false);
  const [openWebcam, setOpenWebCam] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  // Implant
  const [implantCheck, setImplantCheck] = useState(false);
  const [implantValue, setImplantValue] = useState("");
  //Ref
  const poRef = useRef() as MutableRefObject<HTMLDivElement>;
  const teamDetail = props.ORPostOperationSequence?.teamDetail;
  const nurseImplant = props.ORPostOperationSequence?.nurseImplant;

  const isDoctor = useMemo(() => {
    return props.DJANGO?.user?.role_types?.includes("DOCTOR");
  }, [props.DJANGO]);

  useEffect(() => {
    props.runSequence({
      sequence: "ORPostOperation",
      action: "getData",
      orDetailId: props.selectedOrOrder?.operating_detail,
      teamsId: props.selectedOrOrder?.operating_detail[0]?.id,
    });
  }, []);

  useEffect(() => {
    if (poRef.current && props.phvReadOnly) {
      const children = Array.from(
        poRef.current.querySelectorAll(".dropdown, .input, .search, input, textarea, .button")
      ) as HTMLElement[];

      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", "-1");
      });
    }
  }, [poRef?.current, props.phvReadOnly]);

  useEffect(() => {
    if (!isDoctor) {
      const children = Array.from(
        poRef.current.querySelectorAll(
          ".dropdown, .input, .search, input, .ui.button:not([name='PRINT']), .ui.input, .ui.checkbox"
        )
      ) as HTMLElement[];
      const childrenBtn = Array.from(
        poRef.current.querySelectorAll(".ui.button:not([name='PRINT'])")
      ) as HTMLElement[];
      const childrenCheckBtn = Array.from(
        poRef.current.querySelectorAll(".ui.checkbox .checkbox")
      ) as HTMLElement[];
      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.classList.add("block-mouse");
      });
      childrenBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
      });
      childrenCheckBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
        e.classList.add("block-mouse");
      });
    }
  }, [operation, isDoctor]);

  useEffect(() => {
    var newDataTem = [];
    for (var i = 0; i < props.ORPostOperationSequence?.searchTem?.items?.length; i++) {
      newDataTem.push({
        ...props.ORPostOperationSequence?.searchTem?.items[i],
        editStatus: false,
      });
    }
    setDataTem(newDataTem);
  }, [props.ORPostOperationSequence?.searchTem]);

  //SetData
  useEffect(() => {
    //procedures
    setOperation(teamDetail?.post_operating_order_item?.procedures || []);
    //treatment
    setOptProcedure(teamDetail?.post_operating_order_item?.treatment || []);
    //post_secondary_diagnosis
    setSecondary(teamDetail?.post_secondary_diagnosis || []);

    if (teamDetail?.operative_note_id !== null && teamDetail?.operative_note_id !== undefined) {
      props.runSequence({
        sequence: "ORPostOperation",
        action: "getUploadImg",
        operative_note: teamDetail?.operative_note_id,
      });
    }
  }, [teamDetail]);

  useEffect(() => {
    if (teamDetail?.post_implant?.checked) {
      setImplantCheck(teamDetail?.post_implant?.checked);
    } else if (nurseImplant?.type) {
      setImplantCheck(true);
      handleChangeData(null, { name: "post_implant.checked", value: true });
    }

    if (teamDetail?.post_implant?.value) {
      setImplantValue(teamDetail?.post_implant?.value);
    } else if (nurseImplant?.type) {
      // format nurse implant
      const formattedNurseImplant = formatNurseImplantText(nurseImplant?.type);
      setImplantValue(formattedNurseImplant);
    }
  }, [nurseImplant, teamDetail]);

  const teamOptions = useMemo(() => {
    return (props.ORPostOperationSequence?.operatingDetail?.surgery_teams || [])?.map(
      (item: any) => ({
        key: item.id,
        text: item.chief_surgeon,
        value: item.id,
      })
    );
  }, [props.ORPostOperationSequence?.operatingDetail?.surgery_teams]);

  const typeOptions = useMemo(() => {
    return (props.ORPostOperationSequence?.type?.items || [])?.map((item: any) => ({
      key: item.id,
      text: item.label,
      value: item.value,
    }));
  }, [props.ORPostOperationSequence?.type]);

  const swabOptions = useMemo(() => {
    return (props.ORPostOperationSequence?.swab_status?.items || [])?.map((item: any) => ({
      key: item.id,
      text: item.label,
      value: item.value,
    }));
  }, [props.ORPostOperationSequence?.swab_status]);

  const woundOptions = useMemo(() => {
    return (props.ORPostOperationSequence?.type_wound?.items || [])?.map((item: any) => ({
      key: item.id,
      text: item.label,
      value: item.value,
    }));
  }, [props.ORPostOperationSequence?.type_wound]);

  //SaveData
  const handleSaveData = () => {
    if (
      !(
        (teamDetail?.post_principal_diagnosis[0]?.icd_term || "") &&
        (teamDetail?.post_principal_diagnosis[0]?.icd_code || "")
      )
    ) {
      props.setProp(`errorMessage.${CARD_POST_OPERATION}`, "กรุณาระบุ Principle Diagnosis");
    } else {
      props.runSequence({
        sequence: "ORPostOperation",
        action: "saveData",
        post_operating_order_item: {
          ...teamDetail?.post_operating_order_item,
          procedures: operation,
          treatment: optProcedure,
        },
        post_secondary_diagnosis: secondary,
        card: CARD_POST_OPERATION,
        buttonLoadKey: `${CARD_POST_OPERATION}_SAVE`,
      });
    }
  };

  //PrintData
  const handlePrintData = () => {
    props.runSequence({
      sequence: "ORPostOperation",
      action: "printData",
      teamsId: props.selectedOrOrder?.teams[0]?.id,
      patientPanelData: props.patientPanelData,
      buttonLoadKey: `${CARD_POST_OPERATION}_PRINT`,
    });
  };

  //Secondary
  const addSecondary = () => {
    var newSecondary = [];
    newSecondary.push(...secondary, {
      medical_description: "",
      icd_term: "",
      icd_code: "",
      active_disease: false,
    });
    setSecondary(newSecondary);
  };

  const deleteSecondary = (index: any) => {
    var newSecondary = [];
    for (var i = 0; i < secondary.length; i++) {
      if (i !== index) {
        newSecondary.push(secondary[i]);
      }
    }
    setSecondary(newSecondary);
  };

  const clearSecondary = (index: any) => {
    var newSecondary = [];
    for (var i = 0; i < secondary.length; i++) {
      if (i !== index) {
        newSecondary.push(secondary[i]);
      } else {
        newSecondary.push({
          medical_description: "",
          icd_term: "",
          icd_code: "",
          active_disease: false,
        });
      }
    }
    setSecondary(newSecondary);
  };

  const handleSecondary = (item: any, index: any) => {
    var newSecondary = [];
    for (var i = 0; i < secondary.length; i++) {
      if (i !== index) {
        newSecondary.push(secondary[i]);
      } else {
        newSecondary.push({
          icd10: item.icd10_id,
          icd10_med_term: "",
          icd_code: item.icdcode,
          icd_term: item.icdterm,
          id: null,
          medical_description: item.medterm,
          type: 2,
          active_disease: secondary[i].active_disease,
        });
      }
    }
    setSecondary(newSecondary);
  };

  const handleSetMedical = (e: any, index: any, type: any) => {
    var newSecondary = [];
    for (var i = 0; i < secondary.length; i++) {
      if (i !== index) {
        newSecondary.push(secondary[i]);
      } else {
        newSecondary.push({
          medical_description: type === "medterm" ? e : secondary[i].medical_description,
          icd_term: type === "icdterm" ? e : secondary[i].icd_term,
          icd_code: type === "icdcode" ? e : secondary[i].icd_code,
          active_disease: type === "active_disease" ? e : secondary[i].active_disease,
        });
      }
    }
    setSecondary(newSecondary);

    if (filterTimeOut !== null) {
      clearTimeout(filterTimeOut);
    }

    let timeOut: any = setTimeout(() => {
      if (e.length >= 3) {
        setOpenSearchSecondary(index);
        props.runSequence({
          sequence: "ORPostOperation",
          action: "searchICD",
          icdType: "ICD10",
          search: e,
          index: index,
          method: type,
        });
      } else if (e.length < 3) {
        setOpenSearchSecondary(undefined);
        props.setProp(`ORPostOperationSequence.searchPostProcedures`, null);
      }
    }, 800);
    setFilterTimeOut(timeOut);
  };

  //Operation
  const addoperation = () => {
    var newOperation = [];
    newOperation.push(...operation, {
      type: "PRIMARY",
      medical_description: "",
      icd9cm_term: "",
      icd9cm_code: "",
      active_disease: false,
    });
    setOperation(newOperation);
  };

  const deleteOperation = (index: any) => {
    var newOperation = [];
    for (var i = 0; i < operation.length; i++) {
      if (i !== index) {
        newOperation.push(operation[i]);
      }
    }
    setOperation(newOperation);
  };

  const clearOperation = (index: any) => {
    var newOperation = [];
    for (var i = 0; i < operation.length; i++) {
      if (i !== index) {
        newOperation.push(operation[i]);
      } else {
        newOperation.push({
          type: "PRIMARY",
          medical_description: "",
          icd9cm_term: "",
          icd9cm_code: "",
          active_disease: false,
        });
      }
    }
    setOperation(newOperation);
  };

  const handleSelectOperation = (item: any, index: any) => {
    var newOperation = [];
    for (var i = 0; i < operation.length; i++) {
      if (i !== index) {
        newOperation.push(operation[i]);
      } else {
        newOperation.push({
          active_disease: operation[i].active_disease,
          type: operation[i].type,
          icd9cm: item.icd10_id,
          icd9cm_code: item.icdcode,
          icd9cm_med_term: item.medterm_id,
          icd9cm_term: item.icdterm,
          id: null,
          medical_description: item.medterm,
        });
      }
    }
    setOperation(newOperation);
  };

  const handleSetOperation = (e: any, index: any, type: any) => {
    var newOperation = [];
    for (var i = 0; i < operation.length; i++) {
      if (i !== index) {
        newOperation.push(operation[i]);
      } else {
        newOperation.push({
          ...operation[i],
          type: type === "type" ? e : operation[i].type,
          medical_description: type === "medterm" ? e : operation[i].medical_description,
          icd9cm_term: type === "icdterm" ? e : operation[i].icd9cm_term,
          icd9cm_code: type === "icdcode" ? e : operation[i].icd9cm_code,
          active_disease: type === "active_disease" ? e : operation[i].active_disease,
        });
      }
    }
    setOperation(newOperation);

    if (filterTimeOut !== null) {
      clearTimeout(filterTimeOut);
    }

    let timeOut: any = setTimeout(() => {
      if (e.length >= 3) {
        setOpenSearchOperation(index);
        props.runSequence({
          sequence: "ORPostOperation",
          action: "searchICD",
          icdType: "ICD9CM",
          search: e,
          index: index,
          method: type,
        });
      } else if (e.length < 3) {
        setOpenSearchOperation(undefined);
        props.setProp(`ORPostOperationSequence.searchPostProcedures`, null);
      }
    }, 800);
    setFilterTimeOut(timeOut);
  };

  const searchPostProcedures = useMemo(() => {
    return (props?.ORPostOperationSequence?.searchPostProcedures?.data?.response || []).map(
      (item: any) => ({
        ...item,
      })
    );
  }, [props?.ORPostOperationSequence?.searchPostProcedures]);

  const addProcedure = () => {
    var newProcedure = [];
    newProcedure.push(...optProcedure, {
      name_code: "",
    });
    setOptProcedure(newProcedure);
  };

  const deleteProcedure = (index: any) => {
    var newProcedure = [];
    for (var i = 0; i < optProcedure.length; i++) {
      if (i !== index) {
        newProcedure.push(optProcedure[i]);
      }
    }
    if (newProcedure.length === 0) {
      newProcedure.push({
        name_code: "",
      });
    }
    setOptProcedure(newProcedure);
  };

  const handleAddProcedure = (item: any, index: any) => {
    var newProcedure = [];
    for (var i = 0; i < optProcedure.length; i++) {
      if (i !== index) {
        newProcedure.push(optProcedure[i]);
      } else {
        newProcedure.push({
          name_code: item.name_code,
          operating_treatment: item.id,
        });
      }
    }
    setOptProcedure(newProcedure);
  };

  const handleSetProcedure = (e: any, index: any) => {
    var newProcedure = [];
    for (var i = 0; i < optProcedure.length; i++) {
      if (i !== index) {
        newProcedure.push(optProcedure[i]);
      } else {
        newProcedure.push({
          name_code: e,
        });
      }
    }
    setOptProcedure(newProcedure);

    if (filterTimeOut !== null) {
      clearTimeout(filterTimeOut);
    }

    let timeOut: any = setTimeout(() => {
      if (e.length >= 3) {
        setOpenSearchPostResult(index);
        props.runSequence({
          sequence: "ORPostOperation",
          action: "searchPostTreatment",
          search: e,
          index: index,
        });
      } else if (e.length < 3) {
        setOpenSearchPostResult(undefined);
        props.setProp(`ORPostOperationSequence.searchPostResult`, null);
      }
    }, 800);
    setFilterTimeOut(timeOut);
  };

  const searchPostResult = props?.ORPostOperationSequence?.searchPostResult?.data?.items || [];

  const handleSetPrinciple = (e: any, key: any, type: any) => {
    if (filterTimeOut !== null) {
      clearTimeout(filterTimeOut);
    }

    let timeOut: any = setTimeout(() => {
      if (e.length >= 3) {
        if (key === "post_op_pre_principal_diagnosis") {
          setOpenSearchPrePrincipal(true);
        } else if (key === "post_principal_diagnosis") {
          setOpenSearchPostPrincipal(true);
        }

        props.runSequence({
          sequence: "ORPostOperation",
          action: "searchICD",
          icdType: "ICD10",
          search: e,
          method: type,
        });
      } else if (e.length < 3) {
        if (key === "post_op_pre_principal_diagnosis") {
          setOpenSearchPrePrincipal(false);
        } else if (key === "post_principal_diagnosis") {
          setOpenSearchPostPrincipal(false);
        }
        props.setProp(`ORPostOperationSequence.searchPostProcedures`, null);
      }
    }, 800);
    setFilterTimeOut(timeOut);
  };

  const handleAddPrincipleDiagnosis = (item: any, index: any, type: string) => {
    props.setProp(`ORPostOperationSequence.searchPostProcedures`, null);
    props.setProp(`ORPostOperationSequence.teamDetail.${type}.0`, {
      ...teamDetail?.[`${type}`]?.[0],
      icd10: item.icd10_id,
      icd10_med_term: "",
      icd_code: item.icdcode,
      icd_term: item.icdterm,
      id: null,
      medical_description: item.medterm,
      type: 1,
      active_disease: props.teamDetail?.post_op_pre_principal_diagnosis[0]?.active_disease || false,
    });

    if (type === "post_op_pre_principal_diagnosis") {
      setOpenSearchPrePrincipal(false);
    } else if (type === "post_principal_diagnosis") {
      setOpenSearchPostPrincipal(false);
    }
  };

  //Template
  const handleEditValue = (e: any, index: any, type: any) => {
    var newDataTem = [];
    for (var i = 0; i < dataTem.length; i++) {
      if (index === i) {
        newDataTem.push({
          ...dataTem[i],
          name: type === "name" ? e : dataTem[i].name,
          note: type === "note" ? e : dataTem[i].note,
        });
      } else {
        newDataTem.push({
          ...dataTem[i],
        });
      }
    }
    setDataTem(newDataTem);
  };

  const handleUpdateTem: UpdateHandler = (data, isEditing) => {
    if (isEditing) {
      props.runSequence({
        sequence: "ORPostOperation",
        action: "updateTemData",
        pk: data.id,
        name: data.name,
        note: data.note,
      });
    } else {
      let operativeNote = teamDetail?.operative_note;
      props.setProp(
        `ORPostOperationSequence.teamDetail.operative_note`,
        operativeNote ? `${operativeNote}\n\n${data.note}` : data.note
      );
      setOpenTemplate(false);
    }
  };

  const handleDeleteTem = (data: any) => {
    props.runSequence({
      sequence: "ORPostOperation",
      action: "deleteTemData",
      pk: data?.id,
      name: data?.name,
      note: data?.note,
    });
  };

  //UploadImage
  const readChooseFile = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setUploadImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const saveImg = (imgSrc: any) => {
    props.runSequence({
      sequence: "ORPostOperation",
      action: "saveUploadImg",
      operative_note: teamDetail?.operative_note_id,
      image: imgSrc === "" ? uploadImage : imgSrc,
      description: "",
    });
    setOpenUploadImg(false);
    setOpenWebCam(false);
    setUploadImage(null);
  };

  const handleUpdateImage = (is_active: any) => {
    props.runSequence({
      sequence: "ORPostOperation",
      action: "updateUploadImg",
      operative_note: editedImage?.operative_note,
      image: editedImage?.image,
      description: editedImage?.description,
      pk: editedImage?.pk,
      is_active: is_active,
    });
    setOpenImageDetail(false);
    setDeleteImage(false);
  };

  const handleSelectedImage = (item: any) => {
    setSelectedImage(item);
    setOpenModalEditImage(true);
  };

  const handleEditDescription = (e: any) => {
    var newDescription = {
      ...editedImage,
      description: e,
    };
    setEditedImage(newDescription);
  };

  const handleEditedImage = (dataURI: string) => {
    var newImage = {
      ...selectedImage,
      image: dataURI,
    };

    setEditedImage(newImage);
    setOpenImageDetail(true);
  };

  const handleChangeTeam = (e: any, data: any) => {
    const result: any[] = props.ORPostOperationSequence?.operatingDetail?.surgery_teams?.filter(
      (item: any) => item.id === data.value
    );

    props.setProp("ORPostOperationSequence.teamDetail", result?.[0] || null);
  };

  const handleCloseTemplate = () => {
    setOpenTemplate(false);
  };

  const handleSaveTemplate: SaveHandler = (data) => {
    props.runSequence({
      sequence: "ORPostOperation",
      action: "saveTemData",
      ...data,
    });
  };

  const handleSearchTemplate = (searchTem: string) => {
    props.runSequence({
      sequence: "ORPostOperation",
      action: "getTemData",
      search: searchTem,
    });
  };

  const handleOpenTemplate = () => {
    setOpenTemplate(true);

    handleSearchTemplate("");
  };

  const handleChangeData = async(_event: any, data: any) => {
    if (data?.type === "checkbox") {
      props.setProp(`ORPostOperationSequence.teamDetail.${data.name}`, data.checked);
    } else {
      if (!props.ORPostOperationSequence?.teamDetail?.post_operating_order_item) {
        await props.setProp(
          `ORPostOperationSequence.teamDetail.post_operating_order_item`,
          {}
        );
      }

      props.setProp(`ORPostOperationSequence.teamDetail.${data.name}`, data.value);
    }
  };

  const handleChangePrincipal = (data: any, type: string) => {
    if (data?.type === "checkbox") {
      props.setProp(`ORPostOperationSequence.teamDetail.${type}.0.${data.name}`, data.checked);
    } else {
      if (diagnosis[data.name]) {
        handleSetPrinciple(data.value, type, diagnosis[data.name]);
      }

      props.setProp(`ORPostOperationSequence.teamDetail.${type}.0.${data.name}`, data.value);
    }
  };

  const selectPrincipal = (rowInfo: any, column: any, type: string) => {
    return {
      onClick: () => {
        handleAddPrincipleDiagnosis(rowInfo.original, column, type);
      },
    };
  };

  const handleChangeImplantCheckbox = (_event: any, { checked }: { checked: boolean }) => {
    setImplantCheck(checked);
    handleChangeData(_event, { name: "post_implant.checked", value: checked });

    if (!checked) {
      setImplantValue("");
      handleChangeData(_event, { name: "post_implant.value", value: "" });
    }
  };

  const handleChangeImplantValue = (_event: any, { value }: { value: string }) => {
    setImplantValue(value);
    handleChangeData(_event, { name: "post_implant.value", value: value });
  };

  return (
    <div ref={poRef}>
      <div style={{ width: "100%", height: "100%" }}>
        <CardPostOperationUX
          //
          teamDetail={teamDetail}
          operatingDetail={props.ORPostOperationSequence?.operatingDetail}
          chiefSurgeonOptions={teamOptions}
          swabOptions={swabOptions}
          woundOptions={woundOptions}
          openSearchPrePrincipal={openSearchPrePrincipal}
          openSearchPostPrincipal={openSearchPostPrincipal}
          //
          onChangeData={handleChangeData}
          onChangeTeam={handleChangeTeam}
          onChangePrePrincipal={(e: any, data: any) =>
            handleChangePrincipal(data, "post_op_pre_principal_diagnosis")
          }
          onChangePostPrincipal={(e: any, data: any) =>
            handleChangePrincipal(data, "post_principal_diagnosis")
          }
          hideOperatingTreatment={CONFIG.HIDE_OPERATING_TREATMENT_OR}
          // Implant
          onImplantCheck={implantCheck}
          onImplantValue={implantValue}
          onChangeImplantCheck={handleChangeImplantCheckbox}
          onChangeImplantValue={handleChangeImplantValue}
          //Operation
          operationProcedure={
            <div>
              {optProcedure.map((item: any, index: any) => {
                return (
                  <OperationProcedure
                    item={item}
                    index={index}
                    openSearchPostResult={openSearchPostResult}
                    searchPostResult={searchPostResult}
                    handleSetProcedure={handleSetProcedure}
                    setOpenSearchPostResult={setOpenSearchPostResult}
                    handleAddProcedure={handleAddProcedure}
                    deleteProcedure={deleteProcedure}
                  />
                );
              })}
            </div>
          }
          addProcedure={() => addProcedure()}
          operation={
            <div>
              {operation.map((item: any, index: any) => {
                return (
                  <OperationTem
                    item={item}
                    index={index}
                    typeOptions={typeOptions}
                    openSearchOperation={openSearchOperation}
                    searchPostProcedures={searchPostProcedures}
                    handleSelectOperation={handleSelectOperation}
                    setOpenSearchOperation={setOpenSearchOperation}
                    handleSetOperation={handleSetOperation}
                    clearOperation={clearOperation}
                    deleteOperation={deleteOperation}
                  />
                );
              })}
            </div>
          }
          addOperation={() => addoperation()}
          clearPrinciple={() => {
            props.setProp("ORPostOperationSequence.teamDetail.post_principal_diagnosis.0", {
              ...teamDetail?.pospost_principal_diagnosis?.[0],
              icd10_med_term: "",
              icd_term: "",
              icd_code: "",
            });
          }}
          searchPostProcedures={searchPostProcedures}
          prePrincipleDiagnosis={(state: any, rowInfo: any, column: any, instance: any) =>
            selectPrincipal(rowInfo, column, "post_op_pre_principal_diagnosis")
          }
          postPrincipleDiagnosis={(state: any, rowInfo: any, column: any, instance: any) =>
            selectPrincipal(rowInfo, column, "post_principal_diagnosis")
          }
          //Secondary Diagnosis
          secondary_style={secondary[0] ? { marginTop: "10px" } : { visibility: "hidden" }}
          addSecondary={() => addSecondary()}
          secondaryDiagnosis={
            <div>
              {secondary.map((item: any, index: any) => {
                return (
                  <SecondaryDiagnosis
                    item={item}
                    index={index}
                    searchPostProcedures={searchPostProcedures}
                    openSearchSecondary={openSearchSecondary}
                    handleSecondary={handleSecondary}
                    setOpenSearchSecondary={setOpenSearchSecondary}
                    deleteSecondary={deleteSecondary}
                    clearSecondary={clearSecondary}
                    handleSetMedical={handleSetMedical}
                  />
                );
              })}
            </div>
          }
          //Upload
          disabledUpload={teamDetail?.operative_note_id ? false : true}
          takePhoto={() => setOpenWebCam(true)}
          uploadImg={() => setOpenUploadImg(true)}
          imgDetail={
            <ImgDetail
              uploadImg={props.ORPostOperationSequence?.uploadImg}
              selectImage={selectImage}
              openImageDetail={openImageDetail}
              editedImage={editedImage}
              handleUpdateImage={handleUpdateImage}
              handleEditDescription={handleEditDescription}
              setSelectImage={setSelectImage}
              handleSelectedImage={handleSelectedImage}
              deleteImage={deleteImage}
              setDeleteImage={setDeleteImage}
            />
          }
          //
          // saveData={() => handleSaveData()}
          openTemplate={handleOpenTemplate}
          buttonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => handleSaveData()}
              // data
              paramKey={`${CARD_POST_OPERATION}_SAVE`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_POST_OPERATION}_SAVE`]}
              // config
              name="SAVE"
              style={{ width: "100%" }}
              title="Save"
              color="green"
            />
          }
          onPrint={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => handlePrintData()}
              // data
              paramKey={`${CARD_POST_OPERATION}_PRINT`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_POST_OPERATION}_PRINT`]}
              // config
              name="PRINT"
              style={{ width: "100%" }}
              title={intl.formatMessage({ id: "พิมพ์ Operative Note" })}
              color="yellow"
            />
          }
          languageUX={props.languageUX}
        />
      </div>

      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_POST_OPERATION}`, null);
        }}
        error={props.errorMessage?.[CARD_POST_OPERATION]}
        success={null}
        languageUX={props.languageUX}
      />

      {/* Webcam */}
      <WebcamComponent
        open={openWebcam}
        onClose={() => setOpenWebCam(false)}
        setNewImage={(imgSrc: any) => saveImg(imgSrc)}
      />

      {/* EditImage */}
      <ModalEditImage
        image={selectedImage}
        openEditModal={openModalEditImage}
        onCloseModal={() => setOpenModalEditImage(false)}
        cancelImage={() => {
          setOpenModalEditImage(false);
          setOpenImageDetail(false);
        }}
        readOnly={!isDoctor}
        editedImage={(dataURI: string) => handleEditedImage(dataURI)}
      />

      {/* Upload */}
      <Modal open={openUploadImg} size="small" onClose={() => setOpenUploadImg(false)}>
        <div style={{ padding: "15px" }}>
          <div>
            <Button
              color="blue"
              onClick={() => {
                fileUploaderRef.current.click();
              }}
            >
              Choose file
            </Button>
            <input
              type="file"
              id="file"
              ref={fileUploaderRef}
              style={{ display: "none" }}
              onChange={readChooseFile}
              accept="image/*"
            />
          </div>
          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Modal.Description style={{ textAlign: "center" }}>
              <img
                src={uploadImage ? uploadImage : IMAGE.imageBgUpload}
                style={{ maxWidth: 640, maxHeight: 480 }}
                onClick={() => {
                  fileUploaderRef.current.click();
                }}
              />
            </Modal.Description>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              color="green"
              icon
              disabled={!uploadImage}
              labelPosition="left"
              onClick={() => saveImg("")}
            >
              <Icon className="cloud upload"></Icon>
              Upload
            </Button>
          </div>
        </div>
      </Modal>

      {/* Template */}
      <Modal open={openTemplate} size="large" onClose={() => setOpenTemplate(false)}>
        <CardOperativeNoteTemplate
          items={dataTem}
          // callback
          onClose={handleCloseTemplate}
          onSearch={handleSearchTemplate}
          onSave={handleSaveTemplate}
          onUpdate={handleUpdateTem}
          onDelete={handleDeleteTem}
          languageUX={props.languageUX}
        />
      </Modal>
      <div style={{ marginTop: "50px" }}></div>
    </div>
  );
};

const SecondaryDiagnosis = (props: any) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "15px",
        }}
      >
        <div style={styles.divCol}>
          <label style={{ fontWeight: "bold" }}>Medical Term</label>
          <Input
            fluid={true}
            style={{ margin: "0px 10px", width: "100%" }}
            value={props.item.medical_description}
            onChange={(e: any) => props.handleSetMedical(e.target.value, props.index, "medterm")}
          ></Input>
        </div>
        <div style={styles.divCol}>
          <label style={{ fontWeight: "bold" }}>ICD10 Term</label>
          <Input
            fluid={true}
            style={{ margin: "0px 10px", width: "100%" }}
            value={props.item.icd_term}
            onChange={(e: any) => props.handleSetMedical(e.target.value, props.index, "icdterm")}
          ></Input>
        </div>
        <div style={styles.divCol}>
          <label style={{ fontWeight: "bold" }}>ICD10 Code</label>
          <Input
            fluid={true}
            style={{ margin: "0px 10px", width: "100%" }}
            value={props.item.icd_code}
            onChange={(e: any) => props.handleSetMedical(e.target.value, props.index, "icdcode")}
          ></Input>
        </div>
        <div style={{ ...styles.divCol, width: "" }}>
          <Checkbox
            style={{ margin: "0px 10px 0 0" }}
            value={props.item.active_disease}
            onChange={(e: any, { checked }: any) =>
              props.handleSetMedical(checked, props.index, "active_disease")
            }
          ></Checkbox>
          <label style={{ fontWeight: "bold" }}>Active Disease</label>
          <Button
            color="teal"
            icon="erase"
            size="mini"
            style={{ margin: "0px 10px" }}
            onClick={() => props.clearSecondary(props.index)}
          ></Button>
          <Button
            color="red"
            icon="minus"
            size="mini"
            style={{ margin: "0px 0 0 10px" }}
            onClick={() => props.deleteSecondary(props.index)}
          ></Button>
        </div>
      </div>
      <div style={styles.divTable}>
        <div
          style={{
            display: props.openSearchSecondary === props.index ? "block" : "none",
            zIndex: 100,
            width: "calc(100% - 20px)",
          }}
        >
          <Table
            data={props.searchPostProcedures}
            getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
              return {
                onClick: () => {
                  props.setOpenSearchSecondary(undefined);
                  props.handleSecondary(rowInfo.original, props.index);
                },
              };
            }}
            keys="medterm,icdterm,icdcode"
            minRows="4"
            showPagination={false}
            style={{ height: "150px" }}
          ></Table>
        </div>
      </div>
    </div>
  );
};

const OperationProcedure = (props: any) => {
  const intl = useIntl();
  return (
    <div
      style={{
        paddingBottom: "5px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px",
      }}
    >
      <div style={{ width: "10%" }}>
        {props.index === 0 && (
          <div>
            <label style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "รายการหัตถการ" })}</label>
            <label style={{ color: "red" }}>*</label>
          </div>
        )}
      </div>
      <div style={{ width: "80%" }}>
        <Input
          icon="search"
          style={{ width: "100%" }}
          value={props.item?.name_code}
          onChange={(e: any) => props.handleSetProcedure(e.target.value, props.index)}
        ></Input>
        <div
          style={{
            display: props.openSearchPostResult === props.index ? "block" : "none",
            border: "solid #cccccc 1px",
            width: "100%",
            zIndex: 100,
            backgroundColor: "white",
            overflow: "auto",
          }}
        >
          {props.searchPostResult.map((item: any, idx: number) => (
            <div
              key={idx}
              style={{
                cursor: "pointer",
                padding: "5px",
                borderBottom: "1px solid #EEEEEE",
              }}
              onClick={(e: any) => {
                props.setOpenSearchPostResult(undefined);
                props.handleAddProcedure(item, props.index);
              }}
            >
              <div>{item.name}</div>
              <div style={{ color: "rgba(174,174,174)" }}>{item.code}</div>
            </div>
          ))}
        </div>
      </div>
      <div style={{ width: "10%" }}>
        <Button
          color="red"
          icon="minus"
          size="mini"
          onClick={() => props.deleteProcedure(props.index)}
          style={{ margin: "0px 10px" }}
        ></Button>
      </div>
    </div>
  );
};

const OperationTem = (props: any) => {
  const intl = useIntl();
  return (
    <div style={{ marginBottom: "10px" }}>
      <div
        style={{
          paddingBottom: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <label style={{ fontWeight: "bold", minWidth: "3.75rem" }}>{intl.formatMessage({ id: "ประเภท" })}</label>
        <label style={{ color: "red" }}>*</label>
        <div style={{ padding: "0px 10px", width: "60%" }}>
          <Dropdown
            fluid={true}
            options={props.typeOptions}
            selection={true}
            value={props.item.type}
            onChange={(e: any, data: any) =>
              props.handleSetOperation(data.value, props.index, "type")
            }
          ></Dropdown>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "5px",
        }}
      >
        <div style={styles.divCol}>
          <label style={{ fontWeight: "bold", minWidth: "4.05rem" }}>Medical Term</label>
          <Input
            style={{ margin: "0px 10px", width: "100%" }}
            value={props.item.medical_description}
            fluid={true}
            onChange={(e: any) => props.handleSetOperation(e.target.value, props.index, "medterm")}
          ></Input>
        </div>
        <div style={styles.divCol}>
          <label style={{ fontWeight: "bold" }}>ICD9 Term</label>
          <Input
            style={{ margin: "0px 10px", width: "100%" }}
            value={props.item.icd9cm_term}
            fluid={true}
            onChange={(e: any) => props.handleSetOperation(e.target.value, props.index, "icdterm")}
          ></Input>
        </div>
        <div style={styles.divCol}>
          <label style={{ fontWeight: "bold" }}>ICD9 Code</label>
          <Input
            style={{ margin: "0px 10px", width: "100%" }}
            value={props.item.icd9cm_code}
            fluid={true}
            onChange={(e: any) => props.handleSetOperation(e.target.value, props.index, "icdcode")}
          ></Input>
        </div>
        <div style={{ ...styles.divCol, width: "" }}>
          <Checkbox
            style={{ margin: "0px 10px 0 0" }}
            value={props.item.active_disease}
            onChange={(e: any, { checked }: any) =>
              props.handleSetOperation(checked, props.index, "active_disease")
            }
          ></Checkbox>
          <label style={{ fontWeight: "bold" }}>Active Disease</label>
          <Button
            color="teal"
            icon="erase"
            size="mini"
            style={{ margin: "0px 10px" }}
            onClick={() => props.clearOperation(props.index)}
          ></Button>
          <Button
            color="red"
            icon="minus"
            size="mini"
            style={{ margin: "0px 0 0 10px" }}
            onClick={() => props.deleteOperation(props.index)}
          ></Button>
        </div>
      </div>
      <div style={styles.divTable}>
        <div
          style={{
            display: props.openSearchOperation === props.index ? "block" : "none",
            zIndex: 100,
            width: "calc(100% - 20px)",
          }}
        >
          <Table
            data={props.searchPostProcedures}
            getTrProps={(state: any, rowInfo: any, column: any, instance: any) => {
              return {
                onClick: () => {
                  props.setOpenSearchOperation(undefined);
                  props.handleSelectOperation(rowInfo.original, props.index);
                },
              };
            }}
            keys="medterm,icdterm,icdcode"
            minRows="4"
            showPagination={false}
            style={{ height: "150px" }}
          ></Table>
        </div>
      </div>
    </div>
  );
};

const TemData = (props: any) => {
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteTem, setIsDeleteTem] = useState(false);

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleConfirmDelete = () => {
    props.onDeleteTem(props.item);

    setIsDeleteTem(false);
  };

  const handleUpdateTem = () => {
    props.onUpdateTem(props.index, isEditing);

    if (isEditing) {
      handleEditing();
    }
  };

  return (
    <div
      style={{
        padding: "15px",
        marginBottom: "10px",
        backgroundColor: "rgba(255,255,255,255)",
      }}
    >
      <div style={{ marginBottom: "10px" }}>
        {isEditing ? (
          <Input
            fluid={true}
            value={props.item.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.onEditValue(e.target.value, props.index, "name")
            }
          />
        ) : (
          <label style={{ fontSize: "18px" }}>{props.item.name}</label>
        )}
      </div>
      <div style={{ marginBottom: "20px" }}>
        {isEditing ? (
          <Input
            fluid={true}
            value={props.item.note}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.onEditValue(e.target.value, props.index, "note")
            }
          />
        ) : (
          <div>{props.item.note}</div>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            basic
            color="green"
            size="mini"
            onClick={handleUpdateTem}
            style={{ width: "150px" }}
          >
            {isEditing ? intl.formatMessage({id: "บันทึกการแก้ไข"}) : intl.formatMessage({id: "เลือก"})}
          </Button>
          <Button
            basic
            color={isEditing ? "red" : "blue"}
            size="mini"
            onClick={handleEditing}
            style={{ margin: "0px 5px", width: "150px" }}
          >
            {isEditing ? intl.formatMessage({id: "ยกเลิกการแก้ไข"}) : intl.formatMessage({id: "แก้ไข Template"})}
          </Button>
        </div>
        <div style={{ display: isEditing ? "block" : "none" }}>
          <Button
            basic
            color="red"
            size="mini"
            icon="trash alternate"
            onClick={() => setIsDeleteTem(true)}
          />
        </div>
      </div>

      <ModConfirm
        titleColor="yellow"
        openModal={isDeleteTem}
        content={<>ต้องการลบรายการใช่หรือไม่</>}
        onApprove={handleConfirmDelete}
        onDeny={() => setIsDeleteTem(false)}
      />
    </div>
  );
};

const ImgDetail = (props: any) => {
  const intl = useIntl();
  return (
    <Grid divided>
      <Grid.Column width={11}>
        <Grid columns={3}>
          {props.uploadImg?.items.map((item: any, index: any) => {
            return (
              <Grid.Column style={{ padding: "10px", position: "relative" }}>
                <img
                  onClick={() => props.handleSelectedImage(item)}
                  onMouseEnter={() => props.setSelectImage(index)}
                  onMouseLeave={() => props.setSelectImage(undefined)}
                  src={item.image}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <Label
                  pointing
                  style={{
                    display: props.selectImage === index ? "block" : "none",
                    position: "absolute",
                    zIndex: 100,
                    backgroundColor: "white",
                  }}
                >
                  รายละเอียด: {item.description}
                </Label>
              </Grid.Column>
            );
          })}
        </Grid>
      </Grid.Column>
      <Grid.Column width={5}>
        <ModConfirm
          titleColor="red"
          openModal={props.deleteImage}
          content={<>ต้องการลบรูปภาพ ใช่หรือไม่</>}
          onApprove={() => props.handleUpdateImage(false)}
          onDeny={() => props.setDeleteImage(false)}
        />
        {props.openImageDetail && (
          <div>
            <div>
              <img src={props.editedImage?.image} style={{ maxWidth: "100%", height: "auto" }} />
            </div>
            <div>
              <label style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "รายละเอียด" })}</label>
            </div>
            <div style={{ marginTop: "10px 0px" }}>
              <TextArea
                rows={3}
                style={{
                  width: "100%",
                  resize: "none",
                  padding: "10px",
                }}
                value={props.editedImage?.description}
                onChange={(e: any) => props.handleEditDescription(e.target.value)}
              ></TextArea>
            </div>
            <div>
              <Button color="green" onClick={() => props.handleUpdateImage(true)}>
                Update
              </Button>
              <Button
                style={{ margin: "0px 10px" }}
                color="red"
                onClick={() => props.setDeleteImage(true)}
              >
                Remove
              </Button>
            </div>
          </div>
        )}
      </Grid.Column>
    </Grid>
  );
};

const formatNurseImplantText = (text: string) => {
  if (!text) {
    return "";
  }

  const [mainText] = text.split("//");

  return mainText.trim();
};

export default CardPostOperation;
